export enum ConfigID {
  NEWS = 'newsConfig',
}

export type NewsConfig = {
  exclude_replies: boolean
  include_rts: boolean
  screen_name: string
  since_id: string
  tweet_mode: 'extended' | 'compat'
}
