import React from 'react';
import { Link as LinkImport } from 'react-router-dom';
import ColoredText from '../components/CustomText/ColoredText';
const Link = ({ to, children, ...props }) => {
    // It is intended to be an external link
    if (/^https?:\/\//.test(to)) {
        return (React.createElement("a", Object.assign({ href: to, target: "__blank" }, props, { style: { ...props.style, textDecoration: 'none' } }), typeof children === 'string' ? (React.createElement(ColoredText, { style: { textDecorationLine: 'underline' } }, children)) : (children)));
    }
    // Finally, it is an internal link
    return (React.createElement(LinkImport, Object.assign({ to: to }, props, { style: { ...props.style } }), typeof children === 'string' ? (React.createElement(ColoredText, { style: { textDecorationLine: 'underline' } }, children)) : (children)));
};
export default Link;
