import firebase from 'firebase/app'

export enum BundleType {
  BUSINESS = 'business',
  PRO = 'pro',
  FREE = 'free',
  CUSTOM = 'custom',
}

export interface AddonBase {
  key: string
  type: 'boolean' | 'number' | 'enum' | 'plugins' | 'shop'
  price?: number
  value?: string[]
  interval?: number
  factor?: string
  singleton?: boolean
}

export type Addons = Record<string, boolean | number | string[]>

export interface BundleT {
  price: number
  name: string
  type: BundleType
  addons: Addons
  sort: number
}

export type Authorization = Addons

export enum BookingStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive',
}

export interface BookingBase {
  price: number
  key: 'BUNDLE' | 'ADDON'
  startDate: firebase.firestore.Timestamp
  endDate?: firebase.firestore.Timestamp
  status: BookingStatus
}

export interface BundleBooking extends BookingBase {
  bundle: BundleType
  authorizations: BundleT
}

export interface AddonBooking extends BookingBase {
  addon: string
  value: number | string | string[] | boolean
  shopId?: string
}

export type BookingT = BundleBooking | AddonBooking
