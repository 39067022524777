import { Amount } from "../general"

export enum BookingState {
    Pending, //Booking was created, but not paid yet
    Active, //Booking has been paid and is active (will extended at the next intervall run)
    Cancled, //Booking is still active but will be deleted after endDate
    Deleted, //Booking has been deleted -> remove booking
  }

export enum Interval {
    month = 'month',
    year = 'year',
  }
  
export enum Bundle {
    free = 'free',
    business = 'business',
    pro = 'pro',
  }

  export type BundlePrice = {
    month: Amount
    year: Amount
  }