export let _translationFunction;
export let _i18nInstance;
export const _dangerouslyResetModule = () => {
    if (process.env.NODE_ENV !== 'test') {
        throw Error('this function is only for testing');
    }
    _translationFunction = undefined;
    _i18nInstance = undefined;
};
const errorMessage = 'please setup this function first with the translation function and i18n instance';
export const getTranslation = ({ tFunction, i18n } = {}) => {
    if (tFunction) {
        _translationFunction = tFunction;
    }
    if (i18n) {
        _i18nInstance = i18n;
    }
    if (!_translationFunction || !_i18nInstance) {
        throw new Error(errorMessage);
    }
    return { t: _translationFunction, i18n: _i18nInstance };
};
export const useTranslation = () => {
    if (!_translationFunction || !_i18nInstance) {
        throw new Error(errorMessage);
    }
    return { t: _translationFunction, i18n: _i18nInstance };
};
