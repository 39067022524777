import { StyleSheet } from 'react-native';
import { useTextColor } from '../../../hooks/useColors';
import { useFontFamily } from '../../../hooks/useFontFamily';
const useStyles = () => {
    const { regular } = useFontFamily();
    const color = useTextColor();
    return StyleSheet.create({
        text: {
            color,
            textDecorationColor: color,
            fontFamily: regular,
        },
    });
};
export default useStyles;
