import { isThemeDark, useTheme } from '../utils/types/theme';
let _colorMapping;
export const initColorMap = (colorMap) => {
    _colorMapping = Object.freeze(colorMap);
};
const requiresColorMap = (cb) => {
    if (!_colorMapping) {
        throw new Error('please initialise colors with `initColorMap` before using it');
    }
    return cb();
};
export const getTextColor = (theme) => requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.darkText1 : _colorMapping.lightText1));
export const getPrimaryBackground = (theme) => requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.darkBg1 : _colorMapping.lightBg1));
export const getSecondaryBackground = (theme) => requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.darkBg2 : _colorMapping.lightBg2));
export const getHighlightColor = (theme) => requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.highlightDark : _colorMapping.highlightLight));
export const useTextColor = () => getTextColor(useTheme());
export const usePrimaryBackground = () => getPrimaryBackground(useTheme());
export const useSecondaryBackground = () => getSecondaryBackground(useTheme());
export const useHighlightColor = () => getHighlightColor(useTheme());
