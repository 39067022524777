"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CALL_FIRESTORE", {
  enumerable: true,
  get: function get() {
    return _middleware.CALL_FIRESTORE;
  }
});
Object.defineProperty(exports, "actionTypes", {
  enumerable: true,
  get: function get() {
    return _constants.actionTypes;
  }
});
Object.defineProperty(exports, "actions", {
  enumerable: true,
  get: function get() {
    return _actions.firestoreActions;
  }
});
Object.defineProperty(exports, "constants", {
  enumerable: true,
  get: function get() {
    return _constants.default;
  }
});
Object.defineProperty(exports, "createFirestoreInstance", {
  enumerable: true,
  get: function get() {
    return _createFirestoreInstance.default;
  }
});
exports.default = void 0;
Object.defineProperty(exports, "enhancer", {
  enumerable: true,
  get: function get() {
    return _enhancer.default;
  }
});
Object.defineProperty(exports, "firestoreReducer", {
  enumerable: true,
  get: function get() {
    return _reducer.default;
  }
});
Object.defineProperty(exports, "getFirestore", {
  enumerable: true,
  get: function get() {
    return _createFirestoreInstance.getFirestore;
  }
});
Object.defineProperty(exports, "getSnapshotByObject", {
  enumerable: true,
  get: function get() {
    return _query.getSnapshotByObject;
  }
});
Object.defineProperty(exports, "middleware", {
  enumerable: true,
  get: function get() {
    return _middleware.default;
  }
});
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _reducer.default;
  }
});
Object.defineProperty(exports, "reduxFirestore", {
  enumerable: true,
  get: function get() {
    return _enhancer.default;
  }
});
exports.version = void 0;

var _enhancer = _interopRequireDefault(require("./enhancer"));

var _reducer = _interopRequireDefault(require("./reducer"));

var _actions = require("./actions");

var _createFirestoreInstance = _interopRequireWildcard(require("./createFirestoreInstance"));

var _constants = _interopRequireWildcard(require("./constants"));

var _middleware = _interopRequireWildcard(require("./middleware"));

var _query = require("./utils/query");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var version = "0.15.2";
exports.version = version;
var _default = {
  version: version,
  reducer: _reducer.default,
  firestoreReducer: _reducer.default,
  enhancer: _enhancer.default,
  reduxFirestore: _enhancer.default,
  createFirestoreInstance: _createFirestoreInstance.default,
  actions: _actions.firestoreActions,
  getFirestore: _createFirestoreInstance.getFirestore,
  getSnapshotByObject: _query.getSnapshotByObject,
  constants: _constants.default,
  actionTypes: _constants.actionTypes,
  middleware: _middleware.default,
  CALL_FIRESTORE: _middleware.CALL_FIRESTORE
};
exports.default = _default;