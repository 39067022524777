import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { View, StyleSheet } from 'react-native';
import { pick } from 'lodash';
import { useTextColor } from '../hooks/useColors';
const ColoredFontAwesomeIcon = ({ icon, color, ...props }) => {
    const colorFromTheme = useTextColor();
    if (icon === 'spacer') {
        return (React.createElement(View, { style: pick(StyleSheet.flatten(props.style), ['width', 'margin', 'padding', 'paddingRight', 'paddingLeft']) }));
    }
    return React.createElement(FontAwesomeIcon, Object.assign({}, { ...props, icon, color: color || colorFromTheme }));
};
export { ColoredFontAwesomeIcon as FontAwesomeIcon };
