import { StyleSheet, Platform } from 'react-native';
import { useFontFamily } from '../../../hooks/useFontFamily';
const useStyles = () => {
    const { monospace } = useFontFamily();
    return StyleSheet.create({
        text: {
            fontFamily: monospace || Platform.select({ default: 'monospace', ios: 'Courier' }),
        },
    });
};
export default useStyles;
