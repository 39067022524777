import React from 'react';
import { useTranslation } from '../hooks/useTranslation';
import { Redirect, useLocation } from '../react-router';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
const RequireAuth = ({ children, }) => {
    const { t } = useTranslation();
    const currentLocation = useLocation();
    const auth = useAuth();
    if (!auth || !auth.isLoaded) {
        return React.createElement(LoadingScreen, { text: t('loading.auth') });
    }
    if (!auth.isAuthed) {
        return (React.createElement(Redirect, { push: true, to: {
                pathname: '/login',
                state: { referrer: currentLocation },
            } }));
    }
    return React.createElement(React.Fragment, null, children);
};
export default RequireAuth;
