"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapWithFirebaseAndDispatch = mapWithFirebaseAndDispatch;
exports.wrapInDispatch = wrapInDispatch;

var _mapValues2 = _interopRequireDefault(require("lodash/mapValues"));

var _isObject2 = _interopRequireDefault(require("lodash/isObject"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function makePayload(_ref, valToPass) {
  var payload = _ref.payload;
  return typeof payload === 'function' ? payload(valToPass) : payload;
}

function wrapInDispatch(dispatch, _ref2) {
  var ref = _ref2.ref,
      _ref2$meta = _ref2.meta,
      meta = _ref2$meta === void 0 ? {} : _ref2$meta,
      method = _ref2.method,
      _ref2$args = _ref2.args,
      args = _ref2$args === void 0 ? [] : _ref2$args,
      types = _ref2.types;

  var _types = _slicedToArray(types, 3),
      requestingType = _types[0],
      successType = _types[1],
      errorType = _types[2];

  dispatch({
    type: (0, _isObject2.default)(requestingType) ? requestingType.type : requestingType,
    meta: meta,
    payload: (0, _isObject2.default)(requestingType) ? requestingType.payload : {
      args: args
    }
  });
  return ref[method].apply(ref, _toConsumableArray(args)).then(function (result) {
    var successIsObject = (0, _isObject2.default)(successType);
    var actionObj = {
      type: successIsObject ? successType.type : successType,
      meta: meta,
      payload: successIsObject && successType.payload ? makePayload(successType, result) : {
        args: args
      }
    };

    if (successIsObject && successType.preserve) {
      actionObj.preserve = successType.preserve;
    }

    if (successIsObject && successType.merge) {
      actionObj.merge = successType.merge;
    }

    dispatch(actionObj);
    return result;
  }).catch(function (err) {
    dispatch({
      type: errorType,
      meta: meta,
      payload: err
    });
    return Promise.reject(err);
  });
}

function createWithFirebaseAndDispatch(firebase, dispatch) {
  return function (func) {
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return func.apply(firebase, [firebase, dispatch].concat(args));
    };
  };
}

function mapWithFirebaseAndDispatch(firebase, dispatch, actions, aliases) {
  var withFirebaseAndDispatch = createWithFirebaseAndDispatch(firebase, dispatch);
  return _objectSpread(_objectSpread({}, (0, _mapValues2.default)(actions, withFirebaseAndDispatch)), aliases.reduce(function (acc, _ref3) {
    var action = _ref3.action,
        name = _ref3.name;
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, name, withFirebaseAndDispatch(action)));
  }, {}));
}