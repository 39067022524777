import React from 'react';
import { Helmet } from 'react-helmet';
import { Platform } from 'react-native';
import { Switch, Route, Redirect, useLocation, matchPath } from '../react-router';
import ColoredText from '../components/CustomText/ColoredText';
import RequireAuth from './RequireAuth';
const Routes = ({ allRoutes, indexRedirect }) => {
    const location = useLocation();
    const { title } = allRoutes.find(({ route: { path }, title }) => {
        const match = matchPath(location.pathname, path);
        return title && match && match.isExact;
    }) || {};
    return (React.createElement(React.Fragment, null,
        Platform.OS === 'web' && typeof title === 'string' ? (React.createElement(Helmet, null,
            React.createElement("title", null, title))) : null,
        React.createElement(Switch, { location: location },
            allRoutes.map(({ route: { path, children, ...route }, name, requireAuth }) => {
                return (React.createElement(Route, Object.assign({ key: name }, { ...route, path }), requireAuth ? React.createElement(RequireAuth, null, children) : children));
            }),
            indexRedirect ? React.createElement(Redirect, { from: "/", exact: true, to: indexRedirect }) : null,
            __DEV__ ? (React.createElement(Route, { path: "*" }, ({ match }) => React.createElement(ColoredText, null, match.url))) : // for production we need another catch-all route, but for now it can stay that way
                indexRedirect ? (React.createElement(Redirect, { from: "*", exact: true, to: indexRedirect })) : null)));
};
export default Routes;
