import React from 'react';
import FallbackComponent from './FallbackComponent';
class ErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { error: null, hasError: false };
        this.resetError = () => {
            this.setState({ error: null, hasError: false });
        };
    }
    static getDerivedStateFromError(error) {
        return { error, hasError: true };
    }
    componentDidCatch(error, info) {
        if (typeof this.props.onError === 'function') {
            this.props.onError(error, info.componentStack);
        }
    }
    render() {
        const { FallbackComponent } = this.props;
        return this.state.hasError ? (React.createElement(FallbackComponent, { error: this.state.error, resetError: this.resetError })) : (this.props.children);
    }
}
ErrorBoundary.defaultProps = {
    FallbackComponent,
};
export default ErrorBoundary;
