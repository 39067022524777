let _fontFamilyMap;
export const initFontFamily = (fontFamilies) => {
    _fontFamilyMap = Object.freeze(fontFamilies);
};
export const useFontFamily = () => {
    if (!_fontFamilyMap) {
        throw new Error('please execute `initFontFamily` to initialize font families first');
    }
    return _fontFamilyMap;
};
