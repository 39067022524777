import React from 'react';
import { SafeAreaView, Text, TouchableOpacity, View } from 'react-native';
import useStyles from './styles';
const FallbackComponent = (props) => {
    const styles = useStyles();
    return (React.createElement(SafeAreaView, { style: styles.container },
        React.createElement(View, { style: styles.content },
            React.createElement(Text, { style: styles.title }, 'Oops!'),
            React.createElement(Text, { style: styles.subtitle }, "There's an error"),
            React.createElement(Text, { style: styles.error }, props.error.toString()),
            React.createElement(TouchableOpacity, { style: styles.button, onPress: props.resetError },
                React.createElement(Text, { style: styles.buttonText }, 'Try again')))));
};
export default FallbackComponent;
