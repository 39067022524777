import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { ActivityIndicator, View } from 'react-native';
import useStyles from './styles';
import ColoredText from '../CustomText/ColoredText';
const LoadingScreen = ({ text, style }) => {
    const { t } = useTranslation();
    const styles = useStyles();
    return (React.createElement(View, { style: [styles.wrapper, style] },
        React.createElement(ActivityIndicator, { style: styles.spinner, size: "large" }),
        React.createElement(View, { style: styles.spacer }),
        React.createElement(ColoredText, { style: styles.text }, text || t('layout.loading'))));
};
export default LoadingScreen;
